<template>
  <span
    :class="$style.root"
    v-if="isCurrent || isThisWeek || isThisMonth || isUpcoming || (isPast && pastLabel)"
  >
    <span v-if="isCurrent">{{ $t('time.current') }}</span>
    <span v-else-if="isThisWeek">{{ $t('time.thisWeek') }}</span>
    <span v-else-if="isThisMonth">{{ $t('time.thisMonth') }}</span>
    <span v-else-if="isUpcoming">{{ $t('time.upcoming') }}</span>
    <span v-else-if="isPast && pastLabel">{{ $t('time.past') }}</span>
  </span>
</template>

<script setup>
const props = defineProps({
  dateStart: {
    type: [Date, String],
    required: true
  },
  dateEnd: {
    type: [Date, String],
    required: false
  },
  pastLabel: {
    type: Boolean,
    default: false
  }
})

const today = dayJs()
const dateTimeStart = dayJs(props?.dateStart)
const dateTimeEnd = dayJs(props?.dateEnd)

const isCurrent = dateTimeStart.isSameOrBefore(today) && dateTimeEnd.isSameOrAfter(today)
const isThisWeek = dateTimeStart.isSameOrBefore(today, 'week') && dateTimeEnd.isSameOrAfter(today)
const isThisMonth = dateTimeStart.isSameOrBefore(today, 'month') && dateTimeEnd.isSameOrAfter(today)
const isUpcoming = dateTimeStart.isSameOrAfter(today) && dateTimeEnd.isSameOrAfter(today)
const isPast = dateTimeStart.isBefore(today) && dateTimeEnd.isBefore(today)
</script>

<style module>
.root {
  color: var(--core--time-label--color, var(--color--orange));
  text-transform: uppercase;
}
</style>
